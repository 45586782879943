import React from 'react';
import { ReactComponent as Github } from '../assets/github.svg';
import { ReactComponent as Instagram } from '../assets/instagram.svg';
import { ReactComponent as Twitter } from '../assets/twitter.svg';
import { ReactComponent as Mail } from '../assets/mail.svg';

function Home() {
  return (
    <div className="index-container">
      <div className="card-container">
        <div className="name">ALEX CHAN, CFA</div>
        <div className="divider"></div>
        <div className="bio">CRYPTO · CAPITAL MARKETS · CODE</div>
        <div className="social-container">
          <div className="icon-container">
            <a href="https://github.com/alexcdotca">
              <Github />
            </a>
          </div>
          <div className="icon-container">
            <a href="https://www.instagram.com/alexcdotca/">
              <Instagram />
            </a>
          </div>
          <div className="icon-container">
            <a href="https://twitter.com/alexcdotca">
              <Twitter />
            </a>
          </div>
          <div className="icon-container">
            <a href="mailto:hello@alexc.ca">
              <Mail />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
